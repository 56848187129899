<template>
  <div class="pr-3">
    <div class="d-flex justify-content-between align-items-center w-100 my-3">
      <div>
        <p class="text-white">
          Olá <span v-if="username">, {{ username }}</span>!
        </p>
        <p class="text-white">
          Bem vindo ao Hub de Votacões. Aqui você poderá ver seus resultados e principais informações.
        </p>
      </div>
      <vs-button
        :color="cor2"
        :style="btnColors"
        class="mr-4"
        @click="goToNewVoting"
      >
        Nova votação
      </vs-button>
    </div>

    <!-- not Stated  -->
    <vs-table
      v-if="notStartedProjs"
      stripe
      class="pb-4 mb-3"
      :data="notStartedProjs"
      no-data-text="Nenhuma votação programada"
    >
      <template slot="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 my-3"
        >
          <h3 class="text-dark ml-4">
            Programadas
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th>Nome</vs-th>
        <vs-th>Data Início</vs-th>
        <vs-th>Data Final</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Fase</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(proj, i) in data"
          :key="i"
          class="border-top"
        >
          <vs-td class="py-1">
            {{ proj.votationName }}
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationInitDate }}
            </p>
            <p
              v-if="proj.votationInitTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationInitTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationEndDate }}
            </p>
            <p
              v-if="proj.votationEndTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationEndTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn btn bg-transparent border-0 btn btn-secondary text-light"
              title="Iniciar votação"
              icon
              @click="initVoting(proj.projectId, proj.votationCode)"
            >
              Iniciar agora
            </vs-button>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn"
              :color="cor2"
              :style="btnColors"
              title="Publicar"
              icon
              :disabled="proj.phase === 'PROD'"
              @click="publishVoting(proj.surveyId)"
            >
              {{ proj.phase === 'PROD' ? 'Publicado' : 'Publicar' }}
            </vs-button>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Editar votação"
              icon
              @click="goToEditVoting(proj)"
            >
              <i class="fas fa-edit" />
            </vs-button>
            <vs-button
              class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Configurar votação"
              icon
              @click="goToConfigVoting(proj)"
            >
              <i class="fas fa-cog"></i>
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- on Going -->
    <vs-table
      v-if="ongoingProjs"
      stripe
      class="pb-4 mb-3"
      :data="ongoingProjs"
      no-data-text="Nenhuma votação em andamento"
    >
      <template slot="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 my-3"
        >
          <h3 class="text-dark ml-4">
            Em andamento
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th>Nome</vs-th>
        <vs-th>Data Início</vs-th>
        <vs-th>Data Final</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Fase</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(proj, i) in data"
          :key="i"
          class="border-top"
        >
          <vs-td class="py-1">
            {{ proj.votationName }}
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationInitDate }}
            </p>
            <p
              v-if="proj.votationInitTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationInitTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationEndDate }}
            </p>
            <p
              v-if="proj.votationEndTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationEndTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn btn bg-transparent border-0 btn btn-secondary text-light"
              title="Encerrar votação"
              icon
              @click="endVoting(proj.projectId, proj.votationCode)"
            >
              Encerrar agora
            </vs-button>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn"
              :color="cor2"
              :style="btnColors"
              title="Publicar"
              icon
              :disabled="proj.phase === 'PROD'"
              @click="publishVoting(proj.surveyId)"
            >
              {{ proj.phase === 'PROD' ? 'Publicado' : 'Publicar' }}
            </vs-button>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Editar votação"
              icon
              @click="goToEditVoting(proj)"
            >
              <i class="fas fa-edit" />
            </vs-button>
            <vs-button
              class="ml-2 btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Configurar votação"
              icon
              @click="goToConfigVoting(proj)"
            >
              <i class="fas fa-cog"></i>
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <!-- Done -->
    <vs-table
      v-if="finishedProjs"
      stripe
      class="pb-4 mb-3"
      :data="finishedProjs"
      no-data-text="Nenhuma votação encerrada"
    >
      <template slot="header">
        <div
          class="d-flex justify-content-between align-items-center w-100 my-3"
        >
          <h3 class="text-dark ml-4">
            Encerradas
          </h3>
        </div>
      </template>
      <template slot="thead">
        <vs-th>Nome</vs-th>
        <vs-th>Data Início</vs-th>
        <vs-th>Data Final</vs-th>
        <vs-th>Status</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          v-for="(proj, i) in data"
          :key="i"
          class="border-top"
        >
          <vs-td class="py-1">
            {{ proj.votationName }}
            <span v-if="checkItemAccess('Dev')">
              ({{ proj.surveyId }})
            </span>
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationInitDate }}
            </p>
            <p
              v-if="proj.votationInitTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationInitTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1">
            <p class="mb-0">
              <b>Dia:</b> {{ proj.votationEndDate }}
            </p>
            <p
              v-if="proj.votationEndTime"
              class="mb-0"
            >
              <b>Hora: </b>
              {{ proj.votationEndTime }}h
            </p>
          </vs-td>
          <vs-td class="py-1 text-center">
            <span v-if="Date.now() < convertDate(proj.votationInitDate, proj.votationInitTime)">
              <i class="fas fa-calendar-check mr-2" />A iniciar
            </span>
            <span v-if="Date.now() > convertDate(proj.votationInitDate, proj.votationInitTime) && Date.now() < convertDate(proj.votationEndDate, proj.votationEndTime)">
              <i class="fas fa-star mr-2" />Iniciado
            </span>
            <span v-if="Date.now() > convertDate(proj.votationEndDate, proj.votationEndTime)">
              <i class="fas fa-check mr-2" />Concluído
            </span>
          </vs-td>
          <vs-td class="py-1 text-center">
            <vs-button
              class="ml-auto btn btn bg-transparent border-0 btn btn-primary text-light"
              title="Visualizar votação"
              @click="goToVotingView(proj.votationCode)"
            >
              <i class="fas fa-chart-pie mr-2" /> Visualizar
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
/* Config */
import { baseUrl } from '@/global';

/* Helpers */
import moment from 'moment';

/* Services */
import axios from 'axios';
import Voting from '@/services/voting';

const voting = new Voting();

export default {
  name: 'Votacoes',
  components: {},
  data: () => ({
    title: 'Votações',
    obj: {},
    eventDialog: false,
    editingEvent: false,
    votingProjs: [],
    notStartedProjs: [],
    finishedProjs: [],
    ongoingProjs: [],
  }),
  computed: {
    ownerId() {
      return this.$store.state.ownerID;
    },
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    cor2() {
      return this.$store.state.configBox.cor2;
    },
    cor2BtnTextColor() {
      return this.$store.state.configBox.cor2TextColor;
    },
    btnColors() {
      if (this.cor2BtnTextColor) {
        return { color: this.cor2BtnTextColor };
      }

      return {}
    },
    username() {
      if (this.$store.state.loginData.identity) {
        return this.$store.state.loginData.identity.name1 || '';
      }
      return ''
    },
  },
  mounted() {
    this.getVotingMgmtProjs();
  },
  methods: {
    checkItemAccess(access) {
      if (!access) return true;
      return this.$store.state.loginData.accessValues.includes(access)
    },
    getVotingMgmtProjs() {
      this.$store.dispatch('set_isLoadingActive', true);
      voting.getVotingMgmtProjs(this.ownerId).then(resp => {
        this.votingProjs = resp
        this.notStartedProjs = resp.notStarted
        this.finishedProjs = resp.finished
        this.ongoingProjs = resp.ongoing
        this.$store.dispatch('set_isLoadingActive', false);
      })
    },
    initVoting(proj, code) {
      this.$store.dispatch('set_isLoadingActive', true);
      voting.initVoting(proj, code).then(resp => {
        this.votingProjs = resp
        this.notStartedProjs = resp.notStarted
        this.finishedProjs = resp.finished
        this.ongoingProjs = resp.ongoing
        this.$store.dispatch('set_isLoadingActive', false);
      })
    },
    endVoting(proj, code) {
      this.$store.dispatch('set_isLoadingActive', true);
      voting.endVoting(proj, code).then(resp => {
        this.votingProjs = resp
        this.notStartedProjs = resp.notStarted
        this.finishedProjs = resp.finished
        this.ongoingProjs = resp.ongoing
        this.$store.dispatch('set_isLoadingActive', false);
      })
    },
    publishVoting(surveyId) {
      this.$store.dispatch('set_isLoadingActive', true);
      const url = `${baseUrl}/SurveyPhase/${surveyId}/PROD`;
      try {
        axios
          .post(url)
          .then(() => {
            this.getVotingMgmtProjs();
          })
          .catch((err) => {
            throw err
          })
          .finally(() => {
            this.$store.dispatch('set_isLoadingActive', false);
          });
      } catch (err) {
        this.$vs.notify({
          title: 'Erro ao publicar',
          text: err,
          color: 'warning',
          icon: 'check_box',
        });
        this.$store.dispatch('set_isLoadingActive', false);
      }
    },
    goToVotingView(votationCode) {
      this.$router.push({ path: '/votacoes/detalhes/' + votationCode });
    },
    goToEditVoting(voting) {
      this.$router.push({
        name: 'Editar Votação',
        params: { votationCode: voting.votationCode, surveyId: voting.surveyId }
      });
    },
    goToConfigVoting(voting) {
      this.$router.push({
        name: 'Configurar Votação',
        params: { votationCode: voting.votationCode, projectId: voting.projectId }
      });
    },
    goToNewVoting(){
      this.$router.push({ path: '/votacoes/new' });
    },
    dateFormated(date) {
      if (!date) return 'Não informado';
      return moment(date).format('DD/MM/yyyy');
    },
    timeFormated(time) {
      if (!time) return 'Não informado';
      return moment(time)
        .locale('pt')
        .format('LT');
    },
    convertDate(date, time) {
      const day = date.split('/')[0]
      const month = date.split('/')[1]
      const year = date.split('/')[2]
      const hour = time.split(':')[0]
      const minute = time.split(':')[1]
      const result = new Date(year, month-1, day, hour, minute).getTime()
      return result
    }
  },
};
</script>

<style></style>
